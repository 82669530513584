import { generalApiModuleDescriptionLenses } from 'root/src/client/logic/api/lenses'
import recordTypeSelector from 'root/src/client/logic/api/selectors/recordTypeSelector'
import moduleDescriptions from 'root/src/shared/descriptions/modules'

const { viewEndpointId } = generalApiModuleDescriptionLenses

export default (state, { moduleId }) => {
	const endpointId = viewEndpointId(moduleId, moduleDescriptions)
	if (typeof endpointId === 'string') {
		return recordTypeSelector(endpointId)
	}
	return recordTypeSelector(endpointId[0])
}
