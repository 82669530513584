import React, { memo } from 'react'
import linkConnector from 'root/src/client/logic/app/connectors/linkConnector'
import Link from 'root/src/client/web/base/Link'
import styles from './style'

const Button = memo(({
	routeId, children, routeParams,
	modalId, displayModal, classes,
	onClick, dispatch, modalProps,
}) => (
	routeId
		? (
			<>
				<Link
					navStyle
					routeId={routeId}
					routeParams={routeParams}
					className={classes.button}
					onClick={onClick ? dispatch(onClick) : ''}
				>{children}
				</Link>
			</>
		)
		: (
			<button
				type="button"
				className={classes.button}
				onClick={() => displayModal(modalId, modalProps || {})}
			>{children}
			</button>
		)
))

export default linkConnector(Button, styles, 'MuiAccountSettingsButton')
