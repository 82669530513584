import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import SvgIcon from '@material-ui/core/SvgIcon'
import scrollTopHandler from 'root/src/client/logic/list/handlers/goTopHandler'

const style = {
	iconContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	goTopContainer: {
		color: 'white',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 20,
		marginBottom: 20,
		fontWeight: 'bold',
		fontSize: 18,
	},
}

const GoTopButton = ({ classes, textColor }) => (
	<div className={classes.goTopContainer} onClick={scrollTopHandler}>
		<div>
			<div className={classes.iconContainer}>
				<SvgIcon>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
						<path d="M0 0h24v24H0z" fill="none" />
					</svg>
				</SvgIcon>
			</div>
			<div style={{ color: textColor || 'white' }}>
				BACK TO TOP
			</div>
		</div>
	</div>
)

export default withStyles(style, { name: 'MuiGoTop' })(GoTopButton)
