import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'

const { viewTotals, viewListProcessing } = apiStoreLenses

export default (state, { moduleId }) => {
	const endpointIds = moduleEndpointIdSelector(state, { moduleId })
	const listProcessing = Object.keys(viewListProcessing(state) || [])
	// Workaround for findLast
	const keys = listProcessing.filter(
		processing => endpointIds.filter(endpointId => processing.includes(endpointId)).length > 0,
	)
	/**
	 * @ToDo that's a hack for current frontend implementation
	 * as listProcessing is storing endpointIds not sorted
	 * so we never know whether we should pick up last element from the list
	 * or first element.
	 * This hack helps to find the correct totalsView and reject undefined elements
	 * @type {*[]}
	 */
	const views = keys.map(key => viewTotals(key, state)).filter(Boolean)
	// Get first rendered view
	return views.length > 0 ? views[0] : undefined
}
