import contains from 'ramda/src/contains'
import path from 'ramda/src/path'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'

const { pathOrLists } = apiStoreLenses

export default	(state, { moduleId }) => {
	const lists = pathOrLists({}, state)
	const keys = Object.keys(lists)
	const endpointId = moduleEndpointIdSelector(state, { moduleId })[0]
	const keysFiltered = keys.filter(contains(endpointId))
	const key = keysFiltered[keysFiltered.length - 1]
	return path([key, 'lastKey'], lists)
}
