import React, { memo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { purple, lightPurple, tertiaryColor } from 'root/src/client/web/commonStyles'

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
	},
	container: {
		position: 'relative',
		display: 'inline-block',
		width: 28,
		height: 14,
	},
	checkbox: {
		opacity: 0,
		height: 0,
		width: 0,
	},
	checkMarkContainer: {
		position: 'absolute',
		cursor: 'pointer',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: '#ccc',
		transition: '.4s',
		borderRadius: 34,
	},
	checkMarkContainerActive: {
		backgroundColor: lightPurple,
	},
	slider: {
		position: 'absolute',
		content: 'u',
		height: 19,
		width: 19,
		left: -3,
		bottom: -2,
		backgroundColor: tertiaryColor,
		transition: '.4s',
		borderRadius: '50%',
		cursor: 'pointer',
	},
	sliderActive: {
		backgroundColor: purple,
		transform: 'translateX(14px)',
	},
	placeholder: {
		paddingRight: 10,
	},
	label: {
		marginLeft: 10,
	},
}

const Toggle = memo(({
	classes, id, className, onChange, checked, label, ref, placeholder,
}) => (
	<div
		ref={ref}
		className={classNames('layout-row', classes.root, className)}
	>
		{placeholder && (
			<span
				className={classNames(classes.label, classes.placeholder)}
				onClick={onChange}
			>{placeholder}
			</span>
		)}
		<label
			id={id}
			className={classes.container}
		>
			<input
				className={classes.checkbox}
				type="checkbox"
				checked={checked}
				onChange={onChange}
			/>
			<span className={
				classNames(
					classes.checkMarkContainer,
					{ [classes.checkMarkContainerActive]: checked },
				)}
			>
				<span className={classNames(classes.checkMark, { [classes.checked]: checked })} />
			</span>
			<span className={classNames(
				classes.slider,
				{ [classes.sliderActive]: checked },
			)}
			/>
		</label>
		{label && (
			<span
				className={classes.label}
				onClick={onChange}
			>
				{label}
			</span>
		)}
	</div>
))

export default withStyles(styles, { name: 'MuiToggle' })(Toggle)
