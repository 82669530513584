/* eslint-disable consistent-return */
import path from 'ramda/src/path'

import moduleDescriptions from 'root/src/shared/descriptions/modules'

export default (state, { moduleId }) => {
	const highlightRowFn = path([moduleId, 'highlightRowFn'], moduleDescriptions)
	if (!highlightRowFn) return
	return highlightRowFn(state)
}
