import withStyles from '@material-ui/core/styles/withStyles'
import { orNull } from 'root/src/shared/util/ramdaPlus'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { memo } from 'react'
import classNames from 'classnames'

const style = {
	buttonProgress: {
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
		'& svg': {
			margin: 0,
		},

	},
	purple: {
		'& circle': {
			color: '#773fb6',
		},
	},
}

const LoadingSpinner = withStyles(style, { name: 'MuiLoadingSpinner' })(
	({ loading, classes, size, className, purple, position = 'absolute' }) => orNull(
		loading,
		<CircularProgress
			size={size || 24}
			className={classNames(
				classes.buttonProgress,
				className,
				purple && classes.purple,
			)}
			style={{ position }}
		/>,
	),
)

export default memo(LoadingSpinner)
