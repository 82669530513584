import moduleDescriptions from 'root/src/shared/descriptions/modules'
import { listModuleLenses } from 'root/src/client/logic/list/lenses'

const { viewDesktopLayoutRow } = listModuleLenses

const desktopLayoutRowSelectorHof = moduleDescriptionsObj => (
	state, { moduleId },
) => viewDesktopLayoutRow(moduleId, moduleDescriptionsObj) && window.innerWidth > 1500

export default desktopLayoutRowSelectorHof(moduleDescriptions)
