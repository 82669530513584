import map from 'ramda/src/map'
import always from 'ramda/src/always'
import is from 'ramda/src/is'
import getRecordSelector from 'root/src/client/logic/api/selectors/getRecordSelector'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import { listModuleLenses } from 'root/src/client/logic/list/lenses'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import publishDropEvent from 'root/src/client/logic/dropEvent/thunks/publishDropEvent'

const { pathOrTableItemActions } = listModuleLenses

export default (state, props) => {
	const item = getRecordSelector(state, props)
	const { moduleId } = props
	const actions = pathOrTableItemActions(moduleId, [], moduleDescriptions)

	const itemFunctions = {	apiRequest, publishDropEvent }

	return map(({
		name, fn, highlighted = always(false), hide = always(false),
		order, header, type, value, centered, fnProps,
	}) => ({
		name: is(String, name) ? name : name && name(item),
		highlighted: highlighted(item),
		fn: fn(item, itemFunctions, fnProps),
		hide: hide(item, state),
		order,
		header,
		type,
		value: value && value(item),
		centered,
	}), actions)
}
