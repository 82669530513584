import reduxConnector from 'root/src/shared/util/reduxConnector'

import getListSelector from 'root/src/client/logic/api/selectors/listSelector'
import listTypeSelector from 'root/src/client/logic/list/selectors/listTypeSelector'
import listTitleSelector from 'root/src/client/logic/list/selectors/listTitleSelector'
import listSubtitleSelector from 'root/src/client/logic/list/selectors/listSubtitleSelector'
import headerTypeSelector from 'root/src/client/logic/list/selectors/headerTypeSelector'
import listControlsSelector from 'root/src/client/logic/list/selectors/listControlsSelector'
import visibleLoadingBlockSelector from 'root/src/client/logic/list/selectors/visibleLoadingBlockSelector'
import goTopButtonSelector from 'root/src/client/logic/list/selectors/goTopButtonSelector'
import desktopLayoutRowSelector from 'root/src/client/logic/list/selectors/desktopLayoutRowSelector'
import lastKeySelector from 'root/src/client/logic/api/selectors/lastKeySelector'
import isAuthenticated from 'root/src/client/logic/auth/selectors/isAuthenticated'
import cardListTypeSelector from 'root/src/client/logic/list/selectors/cardListTypeSelector'
import scrollItemBreakpointsSelector from 'root/src/client/logic/list/selectors/scrollItemBreakpointsSelector'
import tableColumnsSelector from 'root/src/client/logic/list/selectors/tableColumnsSelector'
import recordPageTypeSelector from 'root/src/client/logic/api/selectors/recordPageTypeSelector'
import listRouteIdSelector from 'root/src/client/logic/project/selectors/listRouteIdSelector'
import listToggleVisibilityPropSelector from 'root/src/client/logic/list/selectors/listToggleVisibilityPropSelector'
import listButtonsSelector from 'root/src/client/logic/list/selectors/listButtonsSelector'
import listActionButtonSelector from 'root/src/client/logic/list/selectors/listActionButtonSelector'
import moduleRecordTypeSelector from 'root/src/client/logic/list/selectors/moduleRecordTypeSelector'
import listInfoTextSelector from 'root/src/client/logic/list/selectors/listInfoTextSelector'
import accountDataSelector from 'root/src/client/logic/userData/selectors/accountDataSelector'
import isClientPaginatedSelector from 'root/src/client/logic/list/selectors/isClientPaginatedSelector'
import highlightRowFnSelector from 'root/src/client/logic/list/selectors/highlightRowFnSelector'
import indicatorColumnTypeSelector from 'root/src/client/logic/list/selectors/indicatorColumnTypeSelector'
import dataIdentificatorSelector from 'root/src/client/logic/list/selectors/dataIdentificatorSelector'
import moduleDataFilterSelector from 'root/src/client/logic/api/selectors/moduleDataFilterSelector'

import currentPageSelector from 'root/src/client/logic/list/selectors/currentPageSelector'
import allPageSelector from 'root/src/client/logic/list/selectors/allPageSelector'
import hasMoreSelector from 'root/src/client/logic/list/selectors/hasMoreSelector'
import getNextPage from 'root/src/client/logic/list/thunks/getNextPage'
import getNextOffsetPage from 'root/src/client/logic/list/thunks/getNextOffsetPage'
import getPage from 'root/src/client/logic/list/thunks/getPage'
import tableRowOnClick from 'root/src/client/logic/list/thunks/tableRowOnClick'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import sliderWidthSelector from 'root/src/client/logic/app/selectors/sliderWidthSelector'
import sliderShowItemsSelector from 'root/src/client/logic/app/selectors/sliderShowItemsSelector'
import isMobileResSelector from 'root/src/client/logic/app/selectors/isMobileResSelector'
import projectUrlSelector from 'root/src/client/logic/project/selectors/projectUrlSelector'
import listDispatchFn from 'root/src/client/logic/api/thunks/listDispatchFn'
import recordRoutePropsSelector from 'root/src/client/logic/list/selectors/recordRoutePropsSelector'
import recordDataSelector from 'root/src/client/logic/api/selectors/recordDataSelector'
import pageItemsCountSelector from 'root/src/client/logic/list/selectors/pageItemsCountSelector'
import applicableFiltersSelector from 'root/src/client/logic/api/selectors/applicableFiltersSelector'
import recordDisplayPropSelector from 'root/src/client/logic/list/selectors/recordDisplayPropSelector'
import currentRouteParams from 'root/src/client/logic/route/selectors/currentRouteParams'
import imagePropSelector from 'root/src/client/logic/list/selectors/imagePropSelector'
import totalsSelector from 'root/src/client/logic/api/selectors/totalsSelector'

import tableItemActionsSelector	from 'root/src/client/logic/tableItem/selectors/tableItemActionsSelector'
import pageItemLengthSelector from 'root/src/client/logic/list/selectors/pageItemLengthSelector'
import embeddedContentSelector from 'root/src/client/logic/header/selectors/embeddedContentSelector'
import actionButtonsSelector from 'root/src/client/logic/list/selectors/actionButtonsSelector'
import accordionOnExpand from 'root/src/client/logic/list/selectors/accordionOnExpand'
import onDashboardTimeChange from 'root/src/client/logic/api/thunks/onDashboardTimeChange'
import dispatcher from 'root/src/client/logic/app/thunks/dispatcher'
import imageBorderSelector from 'root/src/client/logic/list/selectors/imageBorderSelector'
import spacerCellFiltersSelector
	from 'root/src/client/logic/list/selectors/spacerCellFiltersSelector'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'

export default reduxConnector(
	[
		['list', getListSelector],
		['listType', listTypeSelector],
		['cardListType', cardListTypeSelector],
		['listTitle', listTitleSelector],
		['listSubtitle', listSubtitleSelector],
		['headerType', headerTypeSelector],
		['listControls', listControlsSelector],
		['currentPage', currentPageSelector],
		['allPage', allPageSelector],
		['hasMore', hasMoreSelector],
		['visibleLoadingBlock', visibleLoadingBlockSelector],
		['pageItemLength', pageItemLengthSelector],
		['goTopButton', goTopButtonSelector],
		['sliderWidth', sliderWidthSelector],
		['sliderShowItems', sliderShowItemsSelector],
		['isMobileRes', isMobileResSelector],
		['desktopLayoutRow', desktopLayoutRowSelector],
		['projectUrl', projectUrlSelector],
		['lastKey', lastKeySelector],
		['isAuthenticated', isAuthenticated],
		['scrollItemBreakpoints', scrollItemBreakpointsSelector],
		['tableColumns', tableColumnsSelector],
		['recordPageType', recordPageTypeSelector],
		['listRouteId', listRouteIdSelector],
		['tableItemActions', tableItemActionsSelector],
		['listToggleVisibilityProp', listToggleVisibilityPropSelector],
		['buttons', listButtonsSelector],
		['embeddedContent', embeddedContentSelector],
		['actionButtons', actionButtonsSelector],
		['accordionOnExpand', accordionOnExpand],
		['listActionButton', listActionButtonSelector],
		['moduleRecordType', moduleRecordTypeSelector],
		['listInfoText', listInfoTextSelector],
		['accountData', accountDataSelector],
		['isClientPaginated', isClientPaginatedSelector],
		['highlightRowFn', highlightRowFnSelector],
		['indicatorColumnType', indicatorColumnTypeSelector],
		['dataIdentificator', dataIdentificatorSelector],
		['moduleDataFilter', moduleDataFilterSelector],
		['recordRouteProps', recordRoutePropsSelector],
		['data', recordDataSelector],
		['pageItemsCount', pageItemsCountSelector],
		['applicableFilters', applicableFiltersSelector],
		['recordDisplayProp', recordDisplayPropSelector],
		['currentRouteParams', currentRouteParams],
		['imageProp', imagePropSelector],
		['totals', totalsSelector],
		['imageBorder', imageBorderSelector],
		['spacerCellFilters', spacerCellFiltersSelector],
		['routeObj', currentRouteObj],
	],
	[
		['getNextPage', getNextPage],
		['getPage', getPage],
		['displayModal', displayModal],
		['rowOnClick', tableRowOnClick],
		['listDispatchFn', listDispatchFn],
		['onDashboardTimeChange', onDashboardTimeChange],
		['dispatch', dispatcher],
		['getNextOffsetPage', getNextOffsetPage],
	],
)
