import findIndex from 'ramda/src/findIndex'
import startsWith from 'ramda/src/startsWith'

import tableRowOnClick from 'root/src/client/logic/coupon/actions/tableRowOnClick'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import clearAllForms from 'root/src/client/logic/form/actions/clearAllForms'
import displayModal from 'root/src/client/logic/modal/actions/displayModal'

import routeDescriptions from 'root/src/shared/descriptions/routes'
import {
	routeDescriptionLenses,
} from 'root/src/client/logic/route/lenses'
import { DELETE_COUPON_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

const { viewModules } = routeDescriptionLenses

export default (type, rowData) => async (dispatch, getState) => {
	const routeId = currentRouteId(getState())
	const moduleIds = viewModules(routeId, routeDescriptions)
	const moduleIndex = findIndex(s => startsWith('COUPON_FORM', s))(moduleIds)
	const moduleKey = `${routeId}-${moduleIds[moduleIndex]}-${moduleIndex}`

	if (type === 'DELETE') {
		return dispatch(displayModal(DELETE_COUPON_MODAL_MODULE_ID, rowData))
	} if (type === 'CLEAR') {
		return dispatch(clearAllForms())
	}
	return dispatch(tableRowOnClick(type, { moduleKey, rowData }))
}
