import is from 'ramda/src/is'
import map from 'ramda/src/map'
import prop from 'ramda/src/prop'
import equals from 'ramda/src/equals'
import moduleDataFilterSelector from 'root/src/client/logic/api/selectors/moduleDataFilterSelector'
import moduleRecordPayloadMapSelector from 'root/src/client/logic/api/selectors/moduleRecordPayloadMapSelector'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import moduleTypeSelector from 'root/src/client/logic/module/selectors/moduleTypeSelector'
import moduleListPayloadSelector from 'root/src/client/logic/api/selectors/moduleListPayloadSelector'
import setCurrentPage from 'root/src/client/logic/list/actions/setCurrentPage'
import { GRAPHS_DASHBOARD_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import allFieldsValuesSelector
	from 'root/src/client/logic/embedded/selectors/allFieldsValuesSelector'
import processSortFilter from 'root/src/client/util/processSortFilter'
import modifyPayloadSelector from 'root/src/client/logic/api/selectors/modifyPayloadSelector'

const payloadSelectorsMap = {
	list: moduleListPayloadSelector,
	static: moduleRecordPayloadMapSelector,
}

export default (moduleId, pageChanged) => async (dispatch, getState) => {
	const state = getState()

	const moduleType = moduleTypeSelector(state, { moduleId })

	const endpointPayloadSelectorFn = payloadSelectorsMap[moduleType]
	const endpointId = moduleEndpointIdSelector(state, { moduleId })
	const payload = endpointPayloadSelectorFn(state, { moduleId })

	const moduleDataFilter =	prop(moduleId, moduleDataFilterSelector(state)) || {}
	const { payloadFilter } = moduleDataFilter

	const sortFilters = allFieldsValuesSelector(state, { moduleId })

	if (!pageChanged) {
		dispatch(setCurrentPage(1, moduleId))
	}

	const finalPayload = {
		...payloadFilter,
		...(!pageChanged && !equals(moduleId, GRAPHS_DASHBOARD_MODULE_ID) ? { offset: 0 } : {}),
	}

	const modifyPayloadFn = modifyPayloadSelector(state, { moduleId })

	if (is(Array, endpointId)) {
		return Promise.all(
			map((endpoint) => {
				const subPayload = prop(endpoint, payload) || payload
				const payloadMod = is(Function, subPayload)
					? subPayload(state)
					: subPayload

				const requestPayload = processSortFilter({
					sortFilters,
					startValue: {
						...payloadMod,
						...finalPayload,
					},
					state,
				})

				const requestPayloadMod = modifyPayloadFn
					? modifyPayloadFn({ payload: requestPayload, moduleDataFilter, state })
					: requestPayload

				dispatch(
					apiRequest(
						endpoint,
						requestPayloadMod,
						{ selfClear: true },
						moduleId,
					),
				)
			}, endpointId),
		)
	}

	const requestPayload = processSortFilter({
		sortFilters,
		startValue: { ...payload, finalPayload },
		state,
	})

	const requestPayloadMod = modifyPayloadFn
		? modifyPayloadFn({ payload: requestPayload, moduleDataFilter, state })
		: requestPayload

	return dispatch(
		apiRequest(
			endpointId,
			requestPayloadMod,
			{ selfClear: true },
			moduleId,
		),
	)
}
