import prop from 'ramda/src/prop'
import is from 'ramda/src/is'
import setCurrentPage from 'root/src/client/logic/list/actions/setCurrentPage'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import moduleListPayloadSelector from 'root/src/client/logic/api/selectors/moduleListPayloadSelector'
import moduleDataFilterSelector from 'root/src/client/logic/api/selectors/moduleDataFilterSelector'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import { head } from 'lodash'

export default (moduleId, hasMore, currentPage, pageItemsCount) => async (dispatch, getState) => {
	const state = getState()

	const endpointId = moduleEndpointIdSelector(state, { moduleId })
	const endpointIdMod = is(Array, endpointId) ? head(endpointId) : endpointId

	const payload = moduleListPayloadSelector(state, { moduleId })
	const { payloadFilter } = prop(moduleId, moduleDataFilterSelector(state)) || {}
	const offset = currentPage * pageItemsCount
	const finalPayload = {
		...payload,
		...payloadFilter,
		offset,
	}

	dispatch(setCurrentPage(currentPage + 1, moduleId))
	dispatch(apiRequest(endpointIdMod, finalPayload, false, moduleId))
}
