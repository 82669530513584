/* eslint-disable consistent-return */
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import add from 'ramda/src/add'
import path from 'ramda/src/path'
import head from 'ramda/src/head'
import setHasMore from 'root/src/client/logic/list/actions/setHasMore'
import getCurrentModuleId from 'root/src/client/logic/route/util/getCurrentModuleId'
import getEndpointIdFromModules from 'root/src/client/logic/route/util/getEndpointIdFromModules'
import sortFilterModuleSelector from 'root/src/client/logic/api/selectors/sortFilterModuleSelector'
import { listStoreLenses } from 'root/src/client/logic/list/lenses'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import { sectionKey, subSectionKey } from 'root/src/shared/constants/keys'
import allFieldsValuesSelector from 'root/src/client/logic/embedded/selectors/allFieldsValuesSelector'
import moduleListPayloadSelector from 'root/src/client/logic/api/selectors/moduleListPayloadSelector'
import visibleLoadingBlockSelector from 'root/src/client/logic/list/selectors/visibleLoadingBlockSelector'

const { viewCurrentPage, viewAllPage } = listStoreLenses

export default (moduleId, hasMore) => async (dispatch, getState) => {
	const state = getState()

	if (visibleLoadingBlockSelector(state, { moduleId })) {
		// infinite scroller sometimes loads next page on tab change
		return
	}

	const currentModuleId = moduleId || getCurrentModuleId()
	const endpointId = getEndpointIdFromModules(state, currentModuleId)
	const endpoint = typeof endpointId === 'string' ? endpointId : head(endpointId)

	if (typeof hasMore === 'string') {
		return dispatch(apiRequest(
			endpoint,
			{
				projectUrl: currentRouteParamsRecordId(state),
				lastKey: hasMore,
			},
			false,
			moduleId,
		))
	}
	const currentPage = viewCurrentPage(moduleId, state)
	const allPage = viewAllPage(moduleId, state)
	const listPayload = moduleListPayloadSelector(state, { moduleId })
	if (currentPage !== undefined && hasMore && currentPage < allPage) {
		dispatch(setHasMore(false, moduleId))
		let { section } = listPayload
		let { subSection } = listPayload
		const sortFilterModule = sortFilterModuleSelector(moduleId)
		const fieldsValues = allFieldsValuesSelector(state, { moduleId: sortFilterModule })
		if (sortFilterModule) {
			section = path(['tab', 'fixedPayload', sectionKey], fieldsValues) || path([sectionKey, 'payload', sectionKey], fieldsValues)
			subSection = path(['tab', 'fixedPayload', subSectionKey], fieldsValues) || path([sectionKey, 'payload', subSectionKey], fieldsValues)
		}

		return dispatch(apiRequest(
			endpoint,
			{
				...listPayload,
				currentPage: add(currentPage, 1),
				filter: path(['sortFilter', sortFilterModule, 'filterParams'], state),
				sortType: path(['sort', 'value'], fieldsValues),
				section,
				subSection,
				// TODO
				//  this projectUrl adds recordId on request we don't need it. not a big deal for now,
				//  but it's source of potential bugs in future
				projectUrl: currentRouteParamsRecordId(state),
			},
			false,
			moduleId,
		))
	}
}
