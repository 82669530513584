/* eslint-disable consistent-return */
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import getEndpointIdFromModules from 'root/src/client/logic/route/util/getEndpointIdFromModules'
import moduleListPayloadSelector from 'root/src/client/logic/api/selectors/moduleListPayloadSelector'
import clearList from 'root/src/client/logic/api/actions/clearList'

export default (page, moduleId) => async (dispatch, getState) => {
	const state = getState()
	const endpointId = getEndpointIdFromModules(state, moduleId)

	dispatch(clearList())

	return dispatch(apiRequest(endpointId, {
		currentPage: page,
		...moduleListPayloadSelector(state, { moduleId }),
	}, false, moduleId))
}
