import { tertiaryColor, ltGtMediaQuery, xsMediaQuery, colorsNew, gtSmMediaQuery } from 'root/src/client/web/commonStyles'

export const listStyle = {
	listModuleContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		padding: 0,
		margin: [[0, 'auto']],
	},
	listModuleContainerRow: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		backgroundColor: colorsNew.dark2,
		padding: 50,
	},
	embeddedContentWrapper: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	tableActions: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'center',
	},
	toggleLabel: {
		fontSize: 12,
		textAlign: 'center',
		marginTop: 3,
	},
	toggleWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	listModuleNewTitles: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		maxWidth: '100%',
		margin: [[40, '10%']],
		[ltGtMediaQuery]: {
			margin: [[20, '10%', 0]],
			maxWidth: 350,
			fontSize: 13,
		},
		'@media(max-width: 414px)': {
			maxWidth: '95%',
		},
	},
	headerWrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 0,
		padding: 0,
		[gtSmMediaQuery]: {
			minWidth: 750,
		},
		[ltGtMediaQuery]: {
			padding: [[10, 40]],
			fontSize: 13,
		},
		'@media(max-width: 414px)': {
			padding: [[10, 5]],
		},
	},
	listModuleTitles: {
		maxWidth: 445,
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		[ltGtMediaQuery]: {
			transform: 'translate(0%, 0%)',
			position: 'relative',
			left: '0%',
		},
	},
	backgroundDesign: {
		marginBottom: -25,
		padding: 20,
		backgroundColor: colorsNew.dark1,
	},
	listModuleTitlesRow: {
		margin: [[55, 'auto', 0]],
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'left',
		fontSize: 16,
		'@media(max-width: 1700px)': {
			maxWidth: 300,
		},
		'@media(max-width: 1530px)': {
			maxWidth: 240,
		},
	},
	listModuleSubtitle: {
		fontSize: 20,
		margin: [[12, 0, 0]],
		[xsMediaQuery]: {
			fontSize: '1.2em',
		},
	},
	listModuleSubtitleRow: {
		margin: [[12, 0, 32]],
		textAlign: 'left',
	},
	buttons: {
		width: 250,
		height: 50,
		margin: [[-10, 'auto', 20]],
	},
	buttonsRow: {
		margin: 0,
		zIndex: 1,
	},
	embeddedContentContainer: {
		zIndex: 10,
	},
	tableActionButtonContainer: {
		marginLeft: 20,
	},
}

const createNewLink = {
	fontSize: 18,
	letterSpacing: 1,
	fontWeight: 'bold',
	textDecoration: 'underline',
	'&:hover': {
		color: tertiaryColor,
	},
}

export const noMatchDareStyle = {
	nothingTextContainer: { fontSize: 20 },
	nothingHeight: {
		height: 275,
		display: 'flex',
		alignItems: 'center',
		'@media (max-width: 414px)': {
			height: 373,
		},
	},
	paddingOffset: {
		justifyContent: 'center',
		padding: 20,
		[ltGtMediaQuery]: {
			padding: 10,
		},
	},
	createNewLink,
}
