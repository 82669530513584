import includes from 'ramda/src/includes'
import head from 'ramda/src/head'
import filter from 'ramda/src/filter'
import reduce from 'ramda/src/reduce'
import map from 'ramda/src/map'
import uniq from 'ramda/src/uniq'
import isNil from 'ramda/src/isNil'
import memoizeWith from 'ramda/src/memoizeWith'
import prop from 'ramda/src/prop'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import moduleDescriptions from 'root/src/shared/descriptions/modules'

const { viewItems, viewListProcessing } = apiStoreLenses

export default memoizeWith(
	({ api }, { moduleId }) => JSON.stringify({ ...prop('lists', api), moduleId }),
	(state, { moduleId }) => {
		const { constantListStoreKeyValue = undefined } = moduleDescriptions[moduleId]
		const endpointId = moduleEndpointIdSelector(state, { moduleId })
		const listProcessing = Object.keys(viewListProcessing(state) || [])
		let moduleProcess
		let list = []
		if (typeof endpointId === 'string') {
			moduleProcess = filter(
				process => (constantListStoreKeyValue && includes(constantListStoreKeyValue, process))
					|| (includes(endpointId, process) && includes(moduleId, process)),
				listProcessing,
			)
			list = viewItems(moduleProcess[0], state)
		} else {
			moduleProcess = reduce(
				(acc, endpoint) => acc.concat(filter(
					process => (constantListStoreKeyValue && includes(constantListStoreKeyValue, process))
						|| includes(endpoint, process) && includes(moduleId, process),
					listProcessing,
				)),	[],	endpointId,
			)
			list = map((item) => {
				const result = viewItems(item, state)
				return !isNil(result) ? result : []
			}, moduleProcess)
			const filterArr = list.slice(list.length - (endpointId.length - 1), list.length)
			list = list.slice(0, list.length - (endpointId.length - 1))
			list = reduce((acc, item) => acc.concat(item), [], list)
			list = uniq([list, ...filterArr])
		}
		// TODO it could be done better, that's shortcut. i'll fix it by the way of doing what I
		//  mentioned in https://github.com/DareDrop-com/DareDrop/pull/954#issuecomment-613465078
		return uniq(head(list))
	},
)
