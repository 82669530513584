import React, { useState, memo, useEffect } from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import LoadingSpinner from 'root/src/client/web/base/LoadingSpinner'
import ButtonNew from 'root/src/client/web/base/ButtonNew'

const styles = {
	root: {
		position: 'relative',
	},
}

const LoadingButtonNew = ({
	children, onClick, classes, className, icon, iconSize, type,
	loading, disabled, iconPosition, htmlType, style, typography, color, border,
}) => {
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		setIsLoading(loading)
		return () => setIsLoading(!loading)
	}, [loading])

	return (
		<div className={classNames(
			classes.root,
			className,
		)}
		>
			<ButtonNew
				htmlType={htmlType}
				style={style}
				typography={typography}
				color={color}
				border={border}
				icon={icon}
				iconPosition={iconPosition}
				iconSize={iconSize}
				disabled={disabled}
				isLoading={isLoading}
				onClick={async (e) => {
					e.preventDefault()
					e.stopPropagation()
					setIsLoading(true)
					const res = await onClick(e)
					setIsLoading(false)
					return res
				}}
				type={type}
			>
				{children}
			</ButtonNew>
			<LoadingSpinner loading={isLoading} />
		</div>
	)
}
export default withStyles(styles, { name: 'MuiLoadingButtonNew' })(memo(LoadingButtonNew))
