/* eslint-disable consistent-return */
import values from 'ramda/src/values'
import { generalApiModuleDescriptionLenses, apiStoreLenses } from 'root/src/client/logic/api/lenses'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import { capitalize } from 'root/src/shared/util/stringCase'

const { viewRecordType } = generalApiModuleDescriptionLenses

export default (state, { moduleId }) => {
	const recordType = viewRecordType(moduleId, moduleDescriptions)
	if (!recordType) return
	const recordsData = apiStoreLenses[`view${capitalize(recordType)}`](state)
	return values(recordsData)
}
