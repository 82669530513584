import map from 'ramda/src/map'
import identity from 'ramda/src/identity'
import values from 'ramda/src/values'
import path from 'ramda/src/path'
import pipe from 'ramda/src/pipe'
import all from 'ramda/src/all'
import reject from 'ramda/src/reject'
import isEmpty from 'ramda/src/isEmpty'
import equals from 'ramda/src/equals'
import is from 'ramda/src/is'
import { ternary } from 'root/src/shared/util/ramdaPlus'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import recordTypeSelector from 'root/src/client/logic/api/selectors/recordTypeSelector'
import { listModuleLenses } from 'root/src/client/logic/list/lenses'

const { viewTableColumns } = listModuleLenses

export default (state, { moduleId }) => {
	const tableColumns = viewTableColumns(moduleId, moduleDescriptions)
	if (is(Function, tableColumns)) {
		return tableColumns(state, { moduleId })
	}
	const endpointId = moduleEndpointIdSelector(state, { moduleId })
	let recordType
	if (typeof endpointId === 'string') {
		recordType = recordTypeSelector(endpointId)
	} else {
		recordType = recordTypeSelector(endpointId[0])
	}
	const records = values(path(['api', 'records', recordType], state))

	return (
		tableColumns
		&& pipe(
			map((column) => {
				const [, colPropName, processor = identity, toggleColumnVisibility] = column
				return ternary(
					toggleColumnVisibility,
					pipe(
						map((record) => {
							const itemData = path(
								Array.isArray(colPropName) ? colPropName : [colPropName],
								record,
							)
							if (!itemData) return undefined
							return processor(itemData, record)
						}),
						all(equals(0)),
					)(records)
						? []
						: column,
					column,
				)
			}),
			reject(isEmpty),
		)(tableColumns)
	)
}
