export default () => {
	let currentOffset = window.pageYOffset
	const time = 400
	const step = currentOffset / time
	const multiplier = 4
	const interval = setInterval(() => {
		currentOffset = window.pageYOffset
		if (currentOffset === 0) {
			clearInterval(interval)
		}
		window.scrollTo(0, currentOffset - step * multiplier)
	}, multiplier)
}
