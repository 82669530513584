import moduleDescriptions from 'root/src/shared/descriptions/modules'
import { listModuleLenses } from 'root/src/client/logic/list/lenses'
import reduce from 'ramda/src/reduce'
import propOr from 'ramda/src/propOr'
import always from 'ramda/src/always'
import omit from 'ramda/src/omit'

const { pathOrButtons } = listModuleLenses
export default (state, { moduleId }) => {
	const buttons = pathOrButtons(moduleId, [], moduleDescriptions)
	return reduce((acc, item) => (propOr(always(true), 'condition', item)(state)
		? [...acc, omit(['condition'], item)] : acc),
	[], buttons)
}
